import React from "react";
import Image from "next/image";
import { Github } from "@styled-icons/boxicons-logos/Github";
import { LinkedinSquare } from "@styled-icons/boxicons-logos/LinkedinSquare";
import { EmailOutline } from "@styled-icons/evaicons-outline/EmailOutline";
import mountains from "../public/images/header-background.jpg";
import felix from "../public/images/felix.jpg";

import styles from "./Main.module.css";

const Main: React.FC = () => {
  return (
    <div>
      <div className={styles.backgroundContainer}>
        <Image
          alt="Mountains"
          src={mountains}
          layout="fill"
          objectFit="cover"
          quality={100}
          placeholder="blur"
        />
      </div>
      <div className={styles.infoContainer}>
        <div className={styles.avatarContainer}>
          <Image
            alt="Avatar"
            src={felix}
            layout="intrinsic"
            width={150}
            height={150}
            quality={30}
            placeholder="blur"
            className={styles.avatar}
          />
        </div>
        <div className={styles.nameText}>
          Félix
          <br />
          Gourdeau
        </div>
        <div className={styles.descriptionText}>
          I&apos;m a Montreal based software engineer focused on project
          requirements, with big picture in mind, creative and organized.
        </div>
        <div
          style={{
            flexDirection: "row",
            marginTop: 15,
          }}
        >
          <a href="http://github.com/felixgourdeau/">
            <Github width={45} height={45} className={styles.icon} />
          </a>
          <a href="https://www.linkedin.com/in/felixgourdeau/">
            <LinkedinSquare width={45} height={45} className={styles.icon} />
          </a>
          <a href="mailto:contact@felixgourdeau.ca">
            <EmailOutline width={45} height={45} className={styles.icon} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Main;
