import React from "react";

import Main from "../components/Main";
import Head from "next/head";

const Index: React.FunctionComponent = () => {
  return (
    <>
      <Head>
        <title>Félix Gourdeau</title>
      </Head>
      <Main />
    </>
  );
};
export default Index;
